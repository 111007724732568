<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row v-if="showFile">
            <b-col cols="12">
                <b-form-group>
                    <template v-slot:label>
                        {{ $t('common.title.upload_file') }}
                    </template>
                    <b-form-file @change="fileChanged" accept=".xlsx,.csv"></b-form-file>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4">
                <b-form-group :label="$t(labelPrefix+'bank_url')">
                    <b-input-group :class="lockedFields.includes('bank_url') ? 'lg_inputs' :'lg_inputs full'">
                        <search-field v-model="form.bank_url_id"
                                      name="bank_urlbank_url_id"
                                      :url="searchUrl"
                                      :additionalParams="{'type':'url'}"
                                      :disableLabel="true"
                                      :callback="urlSelected"
                                      :input="urlChanged"
                                      @input-object="urlSelected"
                                      :readonly="lockedFields.includes('bank_url')"
                        ></search-field>
                        <input type="hidden" name="bank_url" :value="form.bank_url">
                        <b-input-group-append v-if="lockedFields.includes('bank_url')">
                            <b-button @click="lockedFields = lockedFields.filter(field=>{return field!='bank_url'})"
                                      variant="primary" class="form-control-lg">
                                <font-awesome-icon icon="lock"/>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t(labelPrefix+'bank_code')">
                    <b-input-group :class="lockedFields.includes('bank_code') ? 'lg_inputs' :'lg_inputs full'">
                        <search-field v-model="form.bank_code_id"
                                      name="bank_code_id"
                                      :url="searchUrl"
                                      :additionalParams="{'type':'code'}"
                                      :disableLabel="true"
                                      :callback="codeSelected"
                                      :input="codeChanged"
                                      @input-object="codeSelected"
                                      :readonly="lockedFields.includes('bank_code')"
                        ></search-field>
                        <input type="hidden" name="bank_code" :value="form.bank_code">
                        <b-input-group-append v-if="lockedFields.includes('bank_code')">
                            <b-button @click="lockedFields = lockedFields.filter(field=>{return field!='bank_code'})"
                                      variant="primary" class="form-control-lg">
                                <font-awesome-icon icon="lock"/>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t(labelPrefix+'bank_username')">
                    <b-input-group>
                        <b-form-input
                            v-model="form.bank_username"
                            :readonly="lockedFields.includes('bank_username')"
                            size="lg"
                        ></b-form-input>
                        <b-input-group-append v-if="lockedFields.includes('bank_username')">
                            <b-button
                                @click="lockedFields = lockedFields.filter(field=>{return field!='bank_username'})"
                                variant="primary">
                                <font-awesome-icon icon="lock"/>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t(labelPrefix+'bank_pin')">
                    <b-input-group>
                        <b-form-input
                            v-model="form.bank_pin"
                            :readonly="lockedFields.includes('bank_pin')"
                            type="password"
                            size="lg"
                        ></b-form-input>
                        <b-input-group-append v-if="lockedFields.includes('bank_pin')">
                            <b-button @click="lockedFields = lockedFields.filter(field=>{return field!='bank_pin'})"
                                      variant="primary">
                                <font-awesome-icon icon="lock"/>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="4">
                <b-form-group>
                    <template v-slot:label>
                        {{ $t('settings.bank.label.bank_sync_date_margin') }}
                        <b-button variant="info"
                                  v-b-popover.hover.top="$t('settings.bank.help.bank_sync_date_margin')"
                        >
                            <font-awesome-icon icon="info"/>
                        </b-button>
                    </template>
                    <b-form-input v-model="form.bank_sync_date_margin"></b-form-input>
                </b-form-group>
            </b-col>

            <b-col cols="4">
                <b-form-group>
                    <template v-slot:label>
                        {{ $t('settings.bank.label.bank_secondary_map_by_price') }}
                        <b-button variant="info"
                                  v-b-popover.hover.top="$t('settings.bank.help.bank_secondary_map_by_price')"
                        >
                            <font-awesome-icon icon="info"/>
                        </b-button>
                    </template>
                    <zw-switch-group v-model="form.bank_secondary_map_by_price"
                                     name="bank_secondary_map_by_price"
                                     :label-prefix="labelPrefix"
                                     disable-label
                    ></zw-switch-group>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col sm="12" class="text-xs-center mt-2">
                <b-row align-h="end">
                    <b-col sm="6" v-if="hasData">
                        <b-button block @click="connect" variant="primary">
                            {{ $t('bank.button.sync_transactions') }}
                        </b-button>
                    </b-col>
                    <b-col sm="6" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsBank',
    mixins: [commonSave],
    data() {
        return {
            searchUrl: window.apiUrl + '/bank/searchCsvSettings',
            showFile: false,
            loading: true,
            hasData: false,
            defaultForm: {},
            form: {},
            lockedFields: [],
            labelPrefix: 'settings.bank.label.',
        }
    },
    methods: {
        ...mapGetters('Settings', ['getBank']),
        ...mapGetters('CommonData', ['getModules', 'getSources', 'getPlugins', 'getCommonData']),
        change() {
            this.hasData = false
        },
        shown() {
            this.loading = true

            if (this.getCommonData().domain == 'test' || this.getCommonData().domain == 'maintenance') {
                this.showFile = true;
            }

            const settingsBank = this.$store.dispatch('Settings/fetchBank')

            Promise.all([settingsBank]).then(() => {
                this.form = this.getBank();

                ['bank_url', 'bank_code', 'bank_username', 'bank_pin'].forEach(field => {
                    if (this.getBank()[field] && this.getBank()[field].length) {
                        this.lockedFields.push(field)
                    }
                    this.form[field] = null
                })

                this.loading = false
            });
        },
        fileChanged(event) {
            this.loading = true
            this.form.uploadedFile = event.target.files[0]
            let formData = new FormData();
            formData.append('file', this.form.uploadedFile);
            formData.append('id', this.form.id);

            axios.post(window.apiUrl + '/upload-bank-csv', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                'skip_loading': true,
            })
                .then(result => {
                })
                .finally(() => {
                    this.loading = false
                })
        },
        codeChanged(code) {
            this.form.bank_code = code;
        },
        urlChanged(url) {
            this.form.bank_url = url;
        },
        codeSelected(setting) {
            if (!this.lockedFields.includes('bank_url')) {
                this.form.bank_url_id = setting.id
            }
            this.form.bank_code = setting.label
        },
        urlSelected(setting) {
            this.form.bank_url = setting.label
        },
        connect() {
            this.$root.$children[0].openModal('bank-transfers', {}, null, {width: '800px'})
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    window.axios.post(window.apiUrl + '/settings/bank', this.form, {
                        'skip_loading': true,
                    })
                        .then(response => {
                            this.$root.showMessage(
                                this.$t('common.form.title.success'),
                                this.$t('common.toasts.success_updated'),
                                'success'
                            )
                        })
                        .catch((error) => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.shown()
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    },
    mounted() {
        this.shown()
    }
}
</script>
<style>
.lg_inputs input.form-control {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.lg_inputs > span {
    width: calc(100% - 50px);
}

.lg_inputs.full > span {
    width: 100%;
}
</style>